<script>
	import { setGdprVisibility } from '$lib/stores/app/actions';
	import { PAGES } from '$lib/utils/constants';

	export let config = {
		closeButton: '/assets-mkt/template/gdpr-banner/close-icon-v1.png',
		paragraphOne:
			'We use cookies to deliver the best possible experience on our website and to analyse usage.',
		paragraphTwo: `Our <a class="text-[#0A4792] inline underline" href="${PAGES.EXTERNAL.AOFL_ABCMOUSE_COOKIE_POLICY}" target="_blank" rel="noopener noreferrer">Cookie Policy</a> provides further information on our cookie uses and how to amend your cookie settings.`,
		paragraphThree:
			'If you continue to use our website we will assume you are ok with our use of cookies.'
	};

	function hideBanner() {
		setGdprVisibility(false, true);
	}
</script>

<div
	class="z-100 fixed bottom-0 left-0 z-[100] w-full bg-[#EBEAEB] pb-3 pl-[13rem] pr-[13rem] pt-16 shadow-[0_0_20px_0_rgba(128,128,128,1)]"
>
	<div
		class="absolute right-[2.5rem] top-[1.5rem] w-[4.5rem] cursor-pointer"
		role="button"
		tabindex="0"
		on:click={hideBanner}
		on:keydown={hideBanner}
	>
		<img id="close-button" src={config.closeButton} alt="Close banner" />
	</div>
	<div>
		<p class="text-[1.8rem] font-light">{config.paragraphOne}</p>
		<p class="mb-8 text-[1.8rem] font-light">{@html config.paragraphTwo}</p>
		<p class="mb-8 text-[1.8rem] font-light">{config.paragraphThree}</p>
	</div>
</div>
